import React from "react"
import { graphql } from "gatsby"
import { Layout } from "../components"
import { GatsbySeo } from "gatsby-plugin-next-seo"

export default function Template({
  data: { markdownRemark }, // this prop will be injected by the GraphQL query below.
}) {
  const { frontmatter, html } = markdownRemark
  return (
    <Layout>
      <GatsbySeo
        title={frontmatter.seo_title}
        titleTemplate="CG Homeopathie - %s"
        description={frontmatter.seo_description}
      />
      <div
        dangerouslySetInnerHTML={{ __html: html }}
      />
    </Layout>
  )
}

export const pageQuery = graphql`
  query ($id: String!) {
    markdownRemark(id: { eq: $id }) {
      html
      frontmatter {
        slug
        title
        seo_title
        seo_description
      }
    }
  }
`
